<template>
    <div class="create-consignment-note">

        <PageLoader v-if="!pageLoad" />

        <div class="page-title d-flex py-2 justify-content-between">

            <h1
                v-if="edit"
                class="h4 mb-0">
                Редактирование товарной накладной <span v-if="currentConsignmentNote">№ {{ currentConsignmentNote.number }} от {{ currentConsignmentNote.date | parseDate }}</span>
            </h1>
            <h1
                v-else
                class="h4 mb-0">
                Создание товарной накладной
            </h1>

            <div class="d-flex align-items-center">
                <BButton
                    class="ml-3"
                    variant="danger"
                    v-b-modal.confirm-delete>Удалить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="success"
                    @click="createConsigmentNote(true)">Сохранить
                </BButton>
                <BButton
                    class="ml-3"
                    variant="primary"
                    @click="createConsigmentNote()"
                >Отправить на согласование
                </BButton>
            </div>

        </div>

        <b-tabs v-model="tabIndex" card>

            <b-tab title="Информация">

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Филиал заказчик</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <!-- <b-form-select
                            v-model="sendData.organization_id"
                            :state="$v.sendData.$error ? $v.sendData.organization_id.required : null"
                            :options="organizationsOptions.slice(1)"
                        /> -->
                        <v-select
                            label="text"
                            v-bind:class="{ 'v-select-error': $v.sendData.organization_id.$error }"
                            v-bind:appendToBody="true"
                            v-bind:options="organizationsOptions.slice(1)"
                            v-bind:placeholder="organizationsOptions.slice(1).find( el => el.value === sendData.organization_id ) ? organizationsOptions.slice(1).find( el => el.value === sendData.organization_id ).text : ''"
                            v-bind:value="organizationsOptions.slice(1).find( el => el.value === sendData.organization_id )"
                            v-on:input="value => ( sendData.organization_id = value.value )"
                            @search="getOrganizationsOptionsByQuery"
                        >
                            <template #no-options>
                            Нет результатов.
                            </template>
                        </v-select>
                    </div>
                </div>

                <b-row>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Поставщик
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <!-- <b-form-select
                                    v-if="isContractor"
                                    v-model="sendData.provider_contr_agent_id"
                                    :state="$v.sendData.$error ? $v.sendData.provider_contr_agent_id.required : null"
                                    :options="contrAgentsOptions.slice(1)"
                                /> -->
                                <v-select
                                    v-if="isContractor"
                                    label="text"
                                    v-bind:class="{ 'v-select-error': $v.sendData.provider_contr_agent_id.$error }"
                                    v-bind:appendToBody="true"
                                    v-bind:options="contrAgentsOptions.slice(1)"
                                    v-bind:placeholder="contrAgentsOptions.slice(1).find( el => el.value === sendData.provider_contr_agent_id ) ? contrAgentsOptions.slice(1).find( el => el.value === sendData.provider_contr_agent_id ).text : ''"
                                    v-bind:value="contrAgentsOptions.slice(1).find( el => el.value === sendData.provider_contr_agent_id )"
                                    v-on:input="value => ( sendData.provider_contr_agent_id = value.value )"
                                    @search="getContrAgentsOptionsByQuery"
                                >
                                    <template #no-options>
                                        Нет результатов.
                                    </template>
                                </v-select>
                                <div
                                    v-if="isProvider"
                                    style="height: 29px;line-height: 29px;">
                                    {{ user.contr_agent.name }}
                                </div>
                            </div>
                        </div>
                    </b-col>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >№ договора поставки
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <b-form-select
                                    v-model="sendData.provider_contract_id"
                                    :state="$v.sendData.$error ? $v.sendData.provider_contract_id.required : null"
                                    :options="providersContractsOptions.slice(1)"
                                />
                            </div>
                        </div>
                    </b-col>

                </b-row>

                <b-row>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Подрядчик
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <!-- <b-form-select
                                    v-if="isProvider || isContractor"
                                    v-model="sendData.contractor_contr_agent_id"
                                    :state="$v.sendData.$error ? $v.sendData.contractor_contr_agent_id.required : null"
                                    :options="contrAgentsOptions.slice(1)"
                                /> -->
                                <v-select
                                    v-if="isProvider"
                                    label="text"
                                    v-bind:class="{ 'v-select-error': $v.sendData.contractor_contr_agent_id.$error }"
                                    v-bind:appendToBody="true"
                                    v-bind:options="contrAgentsOptions.slice(1)"
                                    v-bind:placeholder="contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id ) ? contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id ).text : ''"
                                    v-bind:value="contrAgentsOptions.slice(1).find( el => el.value === sendData.contractor_contr_agent_id )"
                                    v-on:input="value => ( sendData.contractor_contr_agent_id = value.value )"
                                    @search="getContrAgentsOptionsByQuery"
                                >
                                    <template #no-options>
                                        Нет результатов.
                                    </template>
                                </v-select>
                                <div
                                    v-else-if="isContractor"
                                    style="height: 29px;line-height: 29px;">
                                    {{ user.contr_agent.name }}
                                </div>
                            </div>
                        </div>
                    </b-col>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >№ договора подряда
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <b-form-select
                                    v-model="sendData.work_agreement_id"
                                    :state="$v.sendData.$error ? $v.sendData.work_agreement_id.required : null"
                                    :options="workAgreementsOptions.slice(1)"
                                />
                            </div>
                        </div>
                    </b-col>

                </b-row>

                <div class="d-flex table-bordered mb-1">
                    <div
                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                        style="width: 250px;"
                    >
                        Объект
                    </div>
                    <div class="px-2 py-1 flex-grow-1">
                        <!-- <b-form-select
                            v-model="sendData.customer_object_id"
                            :state="$v.sendData.$error ? $v.sendData.customer_object_id.required : null"
                            :options="objectsOptions"
                        /> -->
                        <v-select
                            label="text"
                            v-bind:options="consignmentObjectsOptions"
                            v-bind:value="consignmentObjectsOptions.find( el => el.value === sendData.customer_object_id )"
                            v-on:input="value => ( sendData.customer_object_id = value.value )"
                            :appendToBody="true"
                            :placeholder="objectsOptions.find( el => el.value === sendData.customer_object_id ) ? objectsOptions.find( el => el.value === sendData.customer_object_id ).text : ''"
                            @search="getObjectsOptionsByQuery"
                            :filterable="false"
                        >
                            <template #no-options>
                            Нет результатов.
                            </template>
                        </v-select>
                    </div>
                </div>

                <div
                    v-if="subObjectsOptions(sendData.customer_object_id) && subObjectsOptions(sendData.customer_object_id).length"
                    class="d-flex table-bordered mb-1"
                >
                    <div
                        class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                        style="width: 250px;"
                    >Подобъект
                    </div>
                    <div class="px-2 py-1 flex-grow-1">
                        <b-form-select
                            v-model="sendData.customer_sub_object_id"
                            :options="subObjectsOptions(sendData.customer_object_id)"
                        />
                    </div>
                </div>

                <b-row>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Ответственный
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <b-form-input
                                    v-model="sendData.responsible_full_name"
                                    :state="$v.sendData.$error ? $v.sendData.responsible_full_name.required : null"
                                    trim/>
                            </div>
                        </div>
                    </b-col>

                    <b-col cols="6">
                        <div class="d-flex table-bordered mb-1">
                            <div
                                class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0"
                                style="width: 250px;"
                            >Телефон
                            </div>
                            <div class="px-2 py-1 flex-grow-1">
                                <b-form-input
                                    v-model="sendData.responsible_phone"
                                    :state="$v.sendData.$error ? $v.sendData.responsible_phone.required : null"
                                    v-mask="'+7 (###) ###-##-##'"
                                    trim/>
                            </div>
                        </div>
                    </b-col>

                </b-row>

                <div class="d-flex table-bordered mb-1">
                    <div class="px-2 py-1 d-flex align-items-center font-weight-bold border-right bg-light text-muted flex-shrink-0" style="width: 250px;">Комментарий</div>
                    <div class="px-2 py-1 flex-grow-1">
                        <b-form-textarea
                            v-model.trim="sendData.comment"
                            :state="$v.sendData.$error ? $v.sendData.comment.required : null"
                            placeholder="Текст комментария"/>
                    </div>
                </div>

            </b-tab>

            <b-tab title="Позиции">

                <CreateTable
                    v-if="orders && orders.length"
                    v-model="positions"
                    :fields="positionFields"
                    :withIterator="true"
                    :orders="orders"
                    @addRow="addConsignmentNotePosition"
                />

                <div
                    v-else
                    class="text-center mt-3 h5 text-danger"
                >
                    По выбранным данным не найдено ни одного заказа. Пожалуйста проверьте правильность введенных данных.
                </div>

            </b-tab>

        </b-tabs>

        <b-modal
            id="confirm-delete"
            title="Удаление товарной накладной"
            centered>
            <p class="m-0">Вы действительно хотите удалить товарную накладную?</p>
            <template #modal-footer="{ hide }">
                <b-button @click="hide()">Нет</b-button>
                <b-button
                    variant="danger"
                    @click="deleteConsigmentNote">Да
                </b-button>
            </template>
        </b-modal>

    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import CreateTable from "@/components/Tables/Create"
import PageLoader from "@/components/UI/PageLoader";
import { debounce } from "debounce";
import axios from 'axios'

export default {
    name: "ConsignmentNoteCreateForm",
    components: {
        PageLoader,
        CreateTable
    },
    props: {
        edit: Boolean
    },
    validations: {
        sendData: {
            contractor_contr_agent_id: {required},
            customer_object_id: {required},
            organization_id: {required},
            provider_contr_agent_id: {required},
            provider_contract_id: {required},
            responsible_full_name: {required},
            responsible_phone: {required},
            work_agreement_id: {required},
            comment: {required}
        }
    },
    computed: {
        id() {
            return this.$route.params.id
        },
        ...mapState({
            user: state => state.account.user,
            currentConsignmentNote: state => state.consignmentNotes.currentConsignmentNote
        }),
        ...mapGetters({
            isProvider: 'isProvider',
            isContractor: 'isContractor',
            organizationsOptions: 'getOrganizationsOptions',
            contrAgentsOptions: 'getContrAgentsOptions',
            providersContractsOptions: 'getProvidersContractsOptions',
            workAgreementsOptions: 'getWorkAgreementsOptions',
            objectsOptions: 'getObjectsOptions',
            subObjectsOptions: 'getSubObjectsOptions'
        }),
        consignmentObject() {
            return { text: this.currentConsignmentNote?.object?.name, value: this.currentConsignmentNote?.object?.id }
        },
        consignmentObjectsOptions() {
            return this.objectsOptions?.filter( el => ( el?.value != this.consignmentObject?.value ) ) || []
        }
    },
    data: () => ({
        sendData: {
            action: 'draft',
            comment: '',
            contractor_contr_agent_id: null,
            customer_object_id: null,
            customer_sub_object_id: null,
            organization_id: null,
            provider_contr_agent_id: null,
            provider_contract_id: null,
            responsible_full_name: '',
            responsible_phone: '',
            work_agreement_id: null
        },
        positions: [
            {
                order_id: null,
                nomenclature_id: null,
                count: null,
                price_without_vat: null,
                vat_rate: null,
                country: null,
                cargo_custom_declaration: '',
                declaration: ''
            }
        ],
        positionFields: [
            {
                key: 'order_id',
                label: '№ заявки',
                full: 'Номер заявки',
                type: 'select'
            },
            {
                key: 'order_date',
                label: 'Дата заявки',
                full: 'Дата заявки',
                type: 'disabled'
            },
            {
                key: 'nomenclature_id',
                label: 'Номенклатура',
                full: 'Номенклатура',
                type: 'select_nomenclature_from_order'
            },
            {
                key: 'mnemocode',
                label: 'Мнемокод',
                full: 'Мнемокод',
                type: 'disabled'
            },
            {
                key: 'count',
                label: 'Количество',
                full: 'Количество',
                type: 'number'
            },
            {
                key: 'price_without_vat',
                label: 'Цена ₽ (без НДС)',
                full: 'Цена ₽ (без НДС)',
                type: 'disabled'
            },
            {
                key: 'vat_rate',
                label: 'Ставка НДС',
                full: 'Ставка НДС',
                type: 'select'
            },
            {
                key: 'amount_without_vat',
                label: 'Сумма ₽ (без НДС)',
                full: 'Сумма ₽ (без НДС)',
                type: 'disable'
            },
            {
                key: 'amount_with_vat',
                label: 'Сумма ₽ (с НДС)',
                full: 'Сумма ₽ (с НДС)',
                type: 'disable'
            },
            {
                key: 'country',
                label: 'Страна',
                full: 'Страна происхождения',
                type: 'select'
            },
            {
                key: 'cargo_custom_declaration',
                label: 'ГДТ',
                full: 'ГДТ',
                type: 'text'
            },
            {
                key: 'declaration',
                label: 'Декларация',
                full: 'Декларация',
                type: 'text'
            }
        ],
        orders: [],
        tabIndex: 0,
        count: 0,
        pageLoad: false
    }),
    watch: {
        'sendData.organization_id'( value ) {
            this.watchForSendData()
            const organization_uuid = this.$store.state.referenceBooks.organizations.find( el => el.id == value ).uuid
            this.$store.dispatch( 'getWorkAgreements', { params: { organization_uuid } } )
            // this.$store.dispatch( 'getProviderContracts', { params: { organization_uuid } } )
        },
        'sendData.provider_contr_agent_id'() { this.watchForSendData() },
        'sendData.contractor_contr_agent_id'() { this.watchForSendData() },
        'sendData.provider_contract_id'() { this.watchForSendData() },
        'sendData.work_agreement_id'() { this.watchForSendData() },
        'sendData.customer_object_id'() {
            this.watchForSendData()
            this.sendData.customer_sub_object_id = null
        },
        'sendData.customer_sub_object_id'() { this.watchForSendData() }
    },
    methods: {
        deleteConsigmentNote () {
            if (this.edit) {
                this.$store.dispatch('deleteConsigmentNote', this.id)
                    .then(() => {
                        this.$bvToast.toast('Товарная накладная успешно удалена', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'success'
                        })
                        this.$router.push('/consignment-notes')
                    })
                    .catch(() => {
                        this.$bvToast.toast('Что-то пошло не так, попробуйте позднее', {
                            toaster: 'b-toaster-top-right',
                            solid: true,
                            appendToast: false,
                            variant: 'danger'
                        })
                    })
            } else {
                this.$router.push('/consignment-notes')
            }
        },
        createConsigmentNote (save = false) {
            if (this.$v.$invalid) {
                this.$v.$touch()
                this.$bvToast.toast('Заполните обязательные поля', {
                    toaster: 'b-toaster-top-right',
                    solid: true,
                    appendToast: false,
                    variant: 'danger'
                })
                return
            }
            this.sendData.action = save ? 'draft' : 'approve'

            if (this.edit) {
                this.$store.dispatch('updateConsignmentNote', { id: this.$route.params.id, data: {...this.sendData, positions: this.positions} }).then(() => {
                    this.sendSuccess('Успешно')
                }).catch(err => {
                    this.sendError(err)
                })
            } else {
                this.$store.dispatch('createConsignmentNote', {...this.sendData, positions: this.positions}).then(() => {
                    this.sendSuccess('Успешно')
                }).catch(err => {
                    this.sendError(err)
                })
            }
        },
        sendSuccess(successText) {
            this.$bvToast.toast(successText, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'success'
            })
            this.$router.push('/consignment-notes')
        },
        sendError(err) {
            const errText = err.response.status === 422 ? 'Проверьте правильность заполненных полей' : 'Ошибка сервера. Попробуйте позднее.'
            this.$bvToast.toast(errText, {
                toaster: 'b-toaster-top-right',
                solid: true,
                appendToast: false,
                variant: 'danger'
            })
        },
        addConsignmentNotePosition() {
            this.positions.push({
                order_id: null,
                nomenclature_id: null,
                count: null,
                price_without_vat: null,
                vat_rate: null,
                country: null,
                cargo_custom_declaration: '',
                declaration: ''
            })
            this.count++
        },
        async getOrders(data) {
            await this.$store.dispatch('getConsignmentsSearchOrders', data).then(resp => {
                this.orders = resp.map(order => ({
                    id: order.id,
                    number: order.number,
                    order_date: order.order_date,
                    nomenclatures: order.positions.filter(position => position.max_available_count !== 0).map(position => ({
                        id: position.nomenclature.id,
                        name: position.nomenclature.name,
                        count: position.nomenclature.count,
                        max_available_count: position.max_available_count,
                        mnemocode: position.nomenclature.mnemocode,
                        price: position.price_without_vat
                    }))
                })).filter(order => order.nomenclatures.length)
            })
        },
        watchForSendData() {
            const data = {
                organization_id: this.sendData.organization_id,
                provider_contr_agent_id: this.sendData.provider_contr_agent_id,
                provider_contract_id: this.sendData.provider_contract_id,
                work_agreement_id: this.sendData.work_agreement_id,
                contractor_contr_agent_id: this.sendData.contractor_contr_agent_id,
                customer_sub_object_id: this.sendData.customer_sub_object_id
            }

            for (let key in data) {
                if (data[key] === null && key !== 'customer_sub_object_id') {
                    return false
                }
            }
            this.getOrders(data)
        },
        getObjectsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getObjectsOptionsSearch(search, loading, this);
        }
        },
        getObjectsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/objects', { params: { search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'objects',
                data: r.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800), 

        getContrAgentsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getContrAgentsOptionsSearch(search, loading, this);
        }
        },
        getContrAgentsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/contr_agents', { params: { name: search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'contrAgents',
                data: r.data.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800),

        getOrganizationsOptionsByQuery(search, loading) {
        if (search.length > 1) {
            loading(true);
            this.getOrganizationsOptionsSearch(search, loading, this);
        }
        },
        getOrganizationsOptionsSearch: debounce(async function (
        search,
        loading,
        vm
        ) {
        axios.get( '/references/organizations', { params: { name: search } } )
            .then( r =>
            vm.$store.commit( 'setReferenceBooks', {
                book: 'organizations',
                data: r.data.data
            }
            ) )
            .finally( () => loading( false ) )
        },
        800),
    },
    async mounted() {
        const id = this.$route.params.id

        if (this.isContractor) {
            this.sendData.contractor_contr_agent_id = this.user.contr_agent.id
        }

        if (this.isProvider) {
            this.sendData.provider_contr_agent_id = this.user.contr_agent.id
        }

        if (this.edit) {
            await this.$store.dispatch('getConsignment', id)
            for (let key in this.sendData) {
                this.sendData[key] = this.currentConsignmentNote[key]
            }
            this.positions = this.currentConsignmentNote.positions
        }
        this.pageLoad = true
    }
}
</script>

<style scoped>

</style>
